import messages from '../assets/messages/nl.json'

export default defineI18nConfig(() => {
  return {
    fallbackLocale: 'nl',
    messages: messages,
    missingWarn: false,
    fallbackWarn: false,
    warnHtmlMessage: false,
  }
})
